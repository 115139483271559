import "./styles/loader.css";
import {useState} from "react";

function Loader({handleLoaderClick}){
    const [hover, setHover] = useState(false);

    return (
        <div className="loaderRoot">
            <div className="loader" hover={hover.toString()}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={handleLoaderClick}
            >
                <p>Zapraszamy</p>
            </div>
        </div>
    )
}

export default Loader

