import { useState } from "react";

import Loader from "./Loader";
import Main from "./pages/Main";



export default function App() {
    const [loading, setLoading] = useState(true);

    function handleClick(){
        setLoading(false)
    }

    if (loading) {
        return <Loader handleLoaderClick={handleClick}/>
    }
    return <Main/>
}
