import "../styles/main.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function Main(){


    return (
        <>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="blogs" element={<Blogs />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
        <div className="Main">

        </div>
        </>
    )
}

export default Main;